import React, { useState, useEffect } from "react"
import { API, graphqlOperation } from "aws-amplify"
import { byId } from "../graphql/queries"
import Amplify from "aws-amplify"
import awsExports from "../../static/scripts/aws-exports"
import Layout from "../components/layout"
import NewsDetails from "../components/newsdetails"
import { navigate } from "gatsby"
import { useQueryParam } from "gatsby-query-params"

const Archive = path => {
  const id = useQueryParam("id", null)
  const [isLoading, setIsLoading] = useState(true)
  const [result, setResult] = useState({
    data: { byId: { info: {}, related: [], text: "" } },
  })

  useEffect(() => {
    async function fetchData() {
      if (id)
        try {
          setIsLoading(true)
          Amplify.configure(awsExports)
          const data = await API.graphql(
            graphqlOperation(byId, { id: id.replace("/index.html", "") })
          )
          setResult(data)
          setIsLoading(false)
        } catch {
          navigate("/404")
        }
    }
    fetchData()
  }, [id])

  return (
    <>
      {isLoading ? (
        <Layout>
          <div className="wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="data-loading">
                    <i className="fal fa-spinner-third"></i>
                    <div>جاري التحميل...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        <NewsDetails
          info={result.data.byId.info}
          related={result.data.byId.related}
          hasRelated={
            result.data.byId.related != null || result.data.byId.related != 0
          }
          text={result.data.byId.text}
        ></NewsDetails>
      )}
    </>
  )
}

export function doShare(info) {
  if (info) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.text = `doShare('${info.title}',"/article/${info.id}")`
    document.body.appendChild(script)
  }
}

export default Archive
